#CardItem1 {
    background: linear-gradient(129deg, #e0fce6 0%, #d8fdff 50%, #e7e0ff 100%);
}


#CardItem2 {
    background: linear-gradient(203.77deg, #dcd7f7 10%, #edd7dd 60%, #fff7f9 100%);
}

#CardItem3 {
    background: linear-gradient(-45deg,  #FBF7D5, #F9DED7);
}

#CardItem4 {
    background: linear-gradient(-45deg,  #F9DED7, #F5CDDE);
}

#CardItem5 {
    background: linear-gradient(-45deg,  #FBF7D5, #E2BEF1);
}

#CardItemVday {
    background: linear-gradient(-45deg,  #fefefe, #fdecec);
}

#CardMedia {
    border-radius: 30px;
}

@media only screen and (max-width: 600px) {
    #CardItem1 {
        max-width: 80vw;
    }
}

@media only screen and (min-width: 800px) {
    #CardMedia {
        background-color: grey;
        height: 600px;
    }
}
