#Buttons {
    position: fixed;
    display: inline;
    right: 18px;
}

#Header {
    display: block;
    padding: 12px;
    min-height: 64px;
    position: fixed;
    top: 0;
    width: 100%;
}

#Heart {
    position: absolute;
    display: inline;
    left: 50%;
    padding: 8px;
}
