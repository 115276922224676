.nav-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

.card {
    width: 200px;
    height: 120px;
    margin: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  a.card  {
    color: inherit; /* Use the default text color */
    text-decoration: none; 
    cursor: pointer; /* Change cursor to pointer on hover to indicate interactivity */
    transition: color 0.3s ease; /* Smooth transition for color change */
  }
  
  a.card:hover {
    color: #58615c; /* Change color on hover */
  }

  .best-card {
    background-color: #d9c1e8; /* Pastel grey background */
  }
  
  .vday-card {
    background-color: #ffd1dc; /* Pastel pink background */
  }

  .year1 {
    background-color: #adf8fa; /* Pastel pink background */
  }