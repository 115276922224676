.App {
  text-align: center;
  overflow-y: hidden;
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: linear-gradient(-45deg, #CCE1F2, #C6F8E5, #FBF7D5, #F9DED7, #F5CDDE, #E2BEF1);
	background-size: 400% 400%;
	animation: background-gradient 10s ease infinite;
  overflow-y: hidden;

}

.Alt-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: hidden;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes background-gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}