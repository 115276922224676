*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

$strokeColor: grey;
$heartColor: #db3434;
$size: 24px; // change this to manipulate overall size
$totalAnim: 7s;
$delay: .1s;
$squareLen: 240;
$circleLen: 188.522;
$heartLen: 308.522;
$svgSize: 90px;
$circleW: 60px;

.heart-loader {
  // position: absolute;
  // display: block;
  // left: 50%;
  // top: 50%;
  // margin-top: $size/-2;
  width: $size;
  height: $size;
  overflow: visible;
  
  &__group {
    transform-origin: 0 $svgSize;
    animation: group-anim $totalAnim $delay infinite;
  }
  
  &__square {
    stroke: $strokeColor;
    stroke-dasharray: $squareLen, $squareLen;
    stroke-dashoffset: $squareLen;
    animation: square-anim $totalAnim $delay infinite;
  }
  
  &__circle {
    stroke: $strokeColor;
    stroke-dasharray: $circleLen, $circleLen;
    stroke-dashoffset: $circleLen;
    transform-origin: $circleW $circleW/2;
    
    &.m--left {
      animation: left-circle-anim $totalAnim $delay infinite;
    }
    
    &.m--right {
      animation: right-circle-anim $totalAnim $delay infinite;
    }
  }
  
  &__heartPath {
    stroke: $heartColor;
    fill: transparent;
    stroke-dasharray: $heartLen, $heartLen;
    stroke-dashoffset: $heartLen;
    animation: heart-anim $totalAnim $delay infinite;
  }
}
@keyframes square-anim {
  12% {
    stroke-dashoffset: 0;
  }
  43% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  85% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}
@keyframes left-circle-anim {
  12% {
    stroke-dashoffset: $circleLen;
  }
  31% {
    stroke-dashoffset: 0;
    transform: translateY(0);
  }
  41% {
    stroke-dashoffset: 0;
    transform: translateY($circleW/-2);
  }
  43% {
    stroke-dashoffset: 0;
    transform: translateY($circleW/-2);
    opacity: 1;
  }
  85% {
    stroke-dashoffset: 0;
    transform: translateY($circleW/-2);
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    transform: translateY($circleW/-2);
    opacity: 0;
  }
}
@keyframes right-circle-anim {
  12% {
    stroke-dashoffset: $circleLen;
  }
  31% {
    stroke-dashoffset: 0;
    transform: translateX(0);
  }
  41% {
    stroke-dashoffset: 0;
    transform: translateX($circleW/2);
  }
  43% {
    stroke-dashoffset: 0;
    transform: translateX($circleW/2);
    opacity: 1;
  }
  85% {
    stroke-dashoffset: 0;
    transform: translateX($circleW/2);
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    transform: translateX($circleW/2);
    opacity: 0;
  }
}
@keyframes group-anim {
  43% {
    transform: rotate(0);
  }
  54% {
    transform: rotate(-45deg);
  }
  90% {
    transform: rotate(-45deg);
    opacity: 1;
  }
  97% {
    transform: rotate(-45deg);
    opacity: 0;
  }
  100% {
    transform: rotate(-45deg);
    opacity: 0;
  }
}
@keyframes heart-anim {
  55% {
    stroke-dashoffset: $heartLen;
    fill: transparent;
  }
  70% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  87% {
    stroke-dashoffset: 0;
    fill: $heartColor;
  }
  100% {
    stroke-dashoffset: 0;
    fill: $heartColor;
  }
}

.other {
  position: absolute;
  left: 0;
  bottom: 0.5rem;
  width: 100%;
  text-align: right;
  
  &__link {
    font-size: 1.3rem;
    margin: 0 1rem;
  }
}